<template>
  <ImportaCSV />
</template>

<script>
import ImportaCSV from "@/components/tabSel/importarTS/ImportaTSCSV.vue";

export default {
  components: {
    ImportaCSV,
  },
};
</script>
